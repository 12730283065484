/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prettier/prettier */

import moment from 'moment/moment';
import * as Yup from 'yup';

export const YupValidationOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

export const removeFirstSpace = (input = '') => input.replace(/^\s+/, '');

export const checkValidEmail = (email) => {
  if (
    email?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  ) {
    return true;
  }
  return false;
};

const mobileRegex = /^\d{10}$/;

export const mobileValidationSchema = Yup.string()
  .required('Mobile number is required')
  .matches(mobileRegex, 'Mobile number must be a valid 10-digit Indian number');

export const checkValidMobile = (value) => {
  if (value?.match(/^[6-9]\d{9}$/)) {
    return true;
  }
  return false;
};

export const findDuplicateInputs = (x, fieldName, stateData) => {
  if (
    stateData?.reduce((acc, curr) => {
      if (
        x?.[fieldName] &&
        curr?.[fieldName]?.toLowerCase() === x?.[fieldName]?.toLowerCase()
      ) {
        return acc + 1;
      }
      return acc;
    }, 0) > 1
  ) {
    return true;
  }
  return false;
};

export const formatDate = (date) => moment(date).format('DD/MMM/YYYY');

export const onlyNumberInput = (input = 0) =>
  input
    .replace(/[^0-9]/g, '')
    .trim()
    .split(' ')
    .join('');

export const onlyNumberWithFlotingInput = (input = '0') =>
  input
    .replace(/[^0-9.]/g, '') // Allow only numbers and dots
    .replace(/^\./, '') // Remove dot if it's the first character
    .replace(/(\..{4})./g, '$1') // Allow only four characters after the dot
    .replace(/(\..*)\./g, '$1') // Allow only one dot
    .trim()
    .split(' ')
    .join('');

export const removeUnderScrSpCharSpaceBet = (pan = '') =>
  pan
    .replace(/[^a-zA-Z0-9]/g, '')
    ?.trim()
    .split(' ')
    .join('');

export default function removeSpecialChars(text) {
  return text.replace(/[^\w\s]/gi, ''); // Removes all non-word characters except spaces
}

export const removeSpecialCharAndNumFn = (input = '') =>
  input
    .trimStart() // Removes leading spaces from the start of the string
    .replace(/\s+/g, ' ')
    .replace(/([^a-zA-Z\s])/g, '');

export function checkTruthy(value) {
  return (
    value === true ||
    value === 1 ||
    (typeof value === 'string' && value.toUpperCase() === 'TRUE') ||
    (typeof value === 'string' && value.toUpperCase() === 'ACTIVE')
  );
}

export const removeUnderScrSpaceBet = (pan = '') =>
  pan?.trim().split(' ').join('');

export const replaceInputToValidPan = (pan) =>
  pan
    .replace(/[^\w\s]/gi, '')
    ?.trim()
    .split(' ')
    .join('')
    ?.toUpperCase();

export async function wrapWithTryCatch(fn) {
  return async function (...args) {
    try {
      return await fn.apply(this, args);
    } catch (error) {
      /* empty */
    }
    return args;
  };
}

export function sliceText(text = '', sliceNumber) {
  // Check if text is undefined or null, return empty string if true
  if (text == null) {
    return '';
  }

  // Convert text to string in case it is not
  text = String(text);

  // Check if sliceNumber is not provided or not a valid number
  if (typeof sliceNumber !== 'number' || sliceNumber <= 0) {
    return text;
  }

  // Slice the text to the specified number of characters
  return text?.slice(0, sliceNumber);
}
export const isString = (value) => typeof value === 'string';
export const toFixedValue = (value) => Math.round(value);

export function fillMissingNumbers(arr) {
  if (!arr?.length) {
    return [];
  }
  const result = [];
  let current = arr[0];

  for (const num of arr) {
    while (current < num) {
      result.push(current);
      current++;
    }
    result.push(num);
    current++;
  }

  return result;
}

export function createObjectWithKeys(inputObject, keysArray) {
  const outputObject = {};

  keysArray.forEach((key) => {
    if (inputObject.hasOwnProperty(key)) {
      outputObject[key] = inputObject[key];
    }
  });

  return outputObject;
}

export function handleUndefined(input) {
  if (input === undefined) {
    return '';
  }
  return input;
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // Optional: adds smooth scrolling animation
  });
};

export const isPlural = (number) => number > 1;
export const toUpperCase = (text) => (text ? `${text?.toUpperCase()}` : '');

export function isOlderThan58WithMonthCheck(dob) {
  // Convert the DOB string to a Date object
  const dobDate = new Date(dob);

  // Calculate the current date
  const currentDate = new Date();

  // Calculate the age difference in years
  const age = currentDate.getFullYear() - dobDate.getFullYear();

  // Check if the person is older than 58
  if (age > 58) {
    return true;
  } else if (age === 58) {
    // If the person is exactly 58 years old, check the month
    const currentMonth = currentDate.getMonth();
    const dobMonth = dobDate.getMonth();

    // Check if at least one month has passed since the last birthday
    return currentMonth > dobMonth;
  } else {
    return false;
  }
}

export function maskAccountNumber(accountNumber) {
  // Extract the last four digits
  const lastFourDigits = accountNumber.slice(-4);

  // Create a masked string with asterisks
  const maskedAccountNumber = `XXXXX${lastFourDigits}`;

  return maskedAccountNumber;
}
export function lastFourDigitsFn(accountNumber = '') {
  if (accountNumber.length < 4) {
    return 'N/A';
  }
  // Extract the last four digits
  const lastFourDigits = accountNumber.slice(-4);

  // Create a masked string with asterisks
  const maskedAccountNumber = `XXXX${lastFourDigits}`;

  return maskedAccountNumber;
}

export function sumProperty(arr, property) {
  const sumValue = parseNumericValue(
    arr?.reduce(
      (acc, cur) => {
        return parseNumericValue(acc) + parseNumericValue(cur[property]);
      },

      0,
    ),
  );

  return sumValue;
}

export const filteredNumbers = (mixedArray, property) =>
  mixedArray.filter((item) => {
    // Use parseFloat() or Number() to convert strings to numbers
    const num = parseFloat(item?.[property]);

    // Check if num is a finite number (not NaN) and not null
    return !isNaN(num) && num !== null;
  });

export function parseNumericValue(input) {
  // If the input is null or undefined, return 0

  if (typeof input === 'number') {
    return input;
  }
  if (input === null || input === undefined) {
    return 0;
  }

  // If the input is a string, try to parse it as a number
  if (typeof input === 'string') {
    const parsedNumber = parseFloat(input);
    // If the parsing results in NaN, return 0
    if (isNaN(parsedNumber)) {
      return 0;
    }
    return Number(parsedNumber);
  }

  // If the input is already a number, return it

  // For any other type of input, return 0
  return 0;
}
export function parseNumericFloatValue(input) {
  // If the input is null or undefined, return 0

  if (typeof input === 'number') {
    return input;
  }
  if (input === null || input === undefined) {
    return 0;
  }

  // If the input is a string, try to parse it as a number
  if (typeof input === 'string') {
    const parsedNumber = parseFloat(input);

    // If the parsing results in NaN, return 0
    if (isNaN(parsedNumber)) {
      return 0;
    }
    return parsedNumber;
  }

  // If the input is already a number, return it

  // For any other type of input, return 0
  return 0;
}

export function parseNumericToFixedValue(input, fiexedValue = 2) {
  // If the input is null or undefined, return 0

  if (typeof input === 'number') {
    return input.toFixed(fiexedValue);
  }
  if (input === null || input === undefined) {
    return 0;
  }

  // If the input is a string, try to parse it as a number
  if (typeof input === 'string') {
    const parsedNumber = parseFloat(input);
    // If the parsing results in NaN, return 0
    if (isNaN(parsedNumber)) {
      return 0;
    }
    return Number(parsedNumber).toFixed(fiexedValue);
  }

  // If the input is already a number, return it

  // For any other type of input, return 0
  return 0;
}

export function toLowerCaseFn(input) {
  // Check if the input is undefined
  if (input === undefined) {
    return '';
  }

  // Ensure the input is a string before converting to lowercase
  if (typeof input === 'string') {
    return input.toLowerCase();
  }

  // If input is not a string, return an empty string
  return '';
}

export const hookformValidateObj = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

export function extractErrorMessages(errors, limit = 5) {
  if (!errors) {
    return ''; // Return an empty string if errors is undefined or null
  }

  // If errors is a single object, convert it to an array with one element
  if (!Array.isArray(errors)) {
    errors = [errors];
  }

  const messages = errors.flatMap((errorObject) =>
    Object.values(errorObject).map((errorDetail) => errorDetail.message),
  );
  const limitedMessages = messages.slice(0, limit);

  return limitedMessages.join(', ');
}

export function getMonthInString(month) {
  let monthString = '';
  if (month === '0') {
    monthString = 'January, ';
  } else if (month === '1') {
    monthString = 'February, ';
  } else if (month === '2') {
    monthString = 'March, ';
  } else if (month === '3') {
    monthString = 'April, ';
  } else if (month === '4') {
    monthString = 'May, ';
  } else if (month === '5') {
    monthString = 'June, ';
  } else if (month === '6') {
    monthString = 'July, ';
  } else if (month === '7') {
    monthString = 'August, ';
  } else if (month === '8') {
    monthString = 'September, ';
  } else if (month === '9') {
    monthString = 'October, ';
  } else if (month === '10') {
    monthString = 'November, ';
  } else if (month === '11') {
    monthString = 'December, ';
  } else {
    monthString = '';
  }

  return monthString;
}

export function formatSlugText(input) {
  if (typeof input === 'undefined' || input === null) {
    return '';
  }
  return input.toLowerCase();
}

export function removeMultipleSpace(input = '') {
  const formattedString = input?.replace(/\s+/g, ' ')?.trimStart();
  return formattedString;
}

export function safeToString(value) {
  return value == null ? '' : String(value);
}

export function toCapitaliseString(str) {
  return str
    .split('')
    .map((char, index) => (index === 0 ? char.toUpperCase() : char))
    .join('');
}

export function replaceMultipleSpaces(input = '') {
  return input
    .trimStart() // Removes leading spaces from the start of the string
    .replace(/\s+/g, ' '); // Replaces multiple spaces with a
}

export const removeSpChar = (input = '') => input?.replace(/[^a-zA-Z0-9]/g, '');

export function generateUniqueId() {
  const now = Date.now(); // Get current date in milliseconds
  const random = Math.floor(Math.random() * 100000); // Generate a random number
  return `${now}-${random}`;
}

export const forOrganizationValidate = (input = '') =>
  input?.replace(/[^a-zA-Z0-9]/g, '');

export function safeTrim(value) {
  if (value === undefined || value === null) {
    return '';
  }
  return value.trim();
}
export const removeSpecialCharAndNumFn1 = (input = '') =>
  input
    .trimStart() // Removes leading spaces from the start of the string
    .replace(/\s+/g, ' ')
    .replace(/([^a-zA-Z\s])/g, '');
